<template>
  <v-row justify="center">
    <v-dialog
      :value="showDialogValue"
      content-class="assign-dialog"
      max-width="700"
      persistent
    >
      <v-card class="pa-8">
        <v-card-title class="d-flex pa-0 mb-2">
          <div>Di chuyển bộ thẻ</div>
        </v-card-title>
        <hr />
        <v-card-text class="pa-0 mb-7">
          <v-tabs v-model="tab" fixed-tabs class="mb-7">
            <v-tab :key="1">
              <h6>Quản lý thẻ gốc</h6>
            </v-tab>
            <v-tab :key="2">
              <h6>Quản lý bộ thẻ Pro</h6>
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item :key="1">
              <treeselect
                v-model="selectedDestinationFolder"
                :options="getNormalFoldersToMove"
                valueFormat="object"
                clearable
                searchable
                disable-branch-nodes
                searchNested
                :maxHeight="250"
                openDirection="below"
                placeholder="Chọn thư mục đích đến muốn di chuyển thẻ."
              />
            </v-tab-item>
            <v-tab-item :key="2">
              <treeselect
                v-model="selectedDestinationFolder"
                :options="getProFoldersToMove"
                valueFormat="object"
                clearable
                searchable
                disable-branch-nodes
                searchNested
                :maxHeight="250"
                openDirection="below"
                placeholder="Chọn thư mục đích đến muốn di chuyển thẻ."
              />
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-card-actions class="d-flex align-center pa-0">
          <v-spacer></v-spacer>
          <v-btn
            class="cancel-btn"
            elevation="0"
            @click="handleCloseDialogMoveCard"
          >
            <h6>Hủy</h6>
          </v-btn>

          <ButtonComponent
            :disable="!selectedDestinationFolder"
            title="Di chuyển"
            class="ml-3"
            @click="showDialogConfirm = true"
          />
        </v-card-actions>
      </v-card>
      <DialogComponent
        v-if="showDialogConfirm"
        typeDialog="confirm"
        description="Chắc chắn di chuyển bộ thẻ này?"
        :showDialogValue="showDialogConfirm"
        @closeDialog="showDialogConfirm = false"
        @confirmRequest="handleConfirmRequest"
      />
    </v-dialog>
  </v-row>
</template>

<script>
import ButtonComponent from "@/components/ui/ButtonComponent.vue";
import DialogComponent from "@/components/ui/DialogComponent.vue";
import { mapState, mapGetters, mapActions } from "vuex";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  components: { ButtonComponent, Treeselect, DialogComponent },
  props: {
    showDialogValue: {
      type: Boolean,
      default: false,
    },
    groupCardId: {
      type: [String, Number],
      required: true,
    },
    fromStudentId: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    return {
      tab: "",
      selectedDestinationFolder: null,
      showDialogConfirm: false,
    };
  },
  async created() {
    await this.fetchProClasses();
  },
  watch: {
    tab() {
      this.selectedDestinationFolder = null;
    },
  },
  computed: {
    ...mapGetters("admin", ["getProFoldersToMove", "getNormalFoldersToMove"]),
    ...mapState("admin", ["currentFolderId"]),
  },
  methods: {
    ...mapActions("admin", [
      "fetchCardSets",
      "moveProCardGroupOfAStudent",
      "moveProCardGroupToOthers",
      "fetchProClasses",
    ]),
    async handleConfirmRequest() {
      if (
        this.selectedDestinationFolder.toStudentId &&
        this.selectedDestinationFolder.toStudentId != this.fromStudentId
      ) {
        await this.moveProCardGroupToOthers({
          groupCardId: this.groupCardId,
          folderId: this.selectedDestinationFolder.toFolderId,
          toUserId: this.selectedDestinationFolder.toStudentId,
          fromUserId: this.fromStudentId,
        });
      } else {
        await this.moveProCardGroupOfAStudent({
          groupCardId: this.groupCardId,
          toUserId: this.selectedDestinationFolder.toStudentId,
          folderId: +(typeof this.selectedDestinationFolder.id === "string" &&
          this.selectedDestinationFolder.id?.includes("_")
            ? this.selectedDestinationFolder.id.split("_")[0]
            : this.selectedDestinationFolder.id),
        });
      }
      if (this.currentFolderId) await this.fetchCardSets(this.currentFolderId);
      await this.fetchProClasses();
      this.$emit("refresh");
      this.showDialogConfirm = false;
      this.handleCloseDialogMoveCard();
    },
    handleCloseDialogMoveCard() {
      this.$emit("closeDialog");
    },
    getImgUrl(url) {
      return require("@/assets/" + url);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .vue-treeselect {
  max-width: calc(100% - 160px);

  .vue-treeselect__menu {
    max-height: 250px;
  }
}

::v-deep .v-card__title {
  flex-direction: column;
  align-items: baseline;
}

::v-deep .assign-dialog,
::v-deep .v-window {
  overflow: visible !important;
}
</style>

<style lang="sass" scoped>
.cancel-btn
  background-color: transparent !important
  text-transform: initial
  color: #453FE3
.v-card
  border-radius: 12px !important
</style>
