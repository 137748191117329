<template>
  <div
    class="countdown"
    :class="timeLeft ? 'animation' : ''"
    :style="{
      'animation-duration': `${time}s`,
      '--t': timeLeft,
    }"
  >
    <!-- animation: `t ${time}s linear infinite`, -->
    <svg viewBox="-50 -50 100 100" stroke-width="10">
      <circle r="45"></circle>
      <circle r="45" pathLength="1"></circle>
    </svg>
  </div>
</template>
<script>
export default {
  data() {
    return {
      timeLeft: 0,
    };
  },
  props: {
    time: Number,
  },
  created() {
    this.timeLeft = this.time;
    let interval = setInterval(() => {
      this.timeLeft--;
    }, 1000);
    setTimeout(() => {
      this.$emit("timeout");
      clearInterval(interval);
    }, this.time * 1000);
  },
};
</script>
<style lang="scss" scoped>
@property --percent {
  syntax: "<number>";
  initial-value: 100;
  inherits: true;
}
/* won't be needed once Chrome supports round() */
@property --s {
  syntax: "<integer>";
  initial-value: 0;
  inherits: true;
}
.countdown {
  --s: calc(var(--t) / 1);
  display: grid;
  margin: 5px 0px auto;
  width: 45px;
  height: 45px;
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  &.animation {
    animation: reduce 0s linear infinite;
  }
}
.countdown::after {
  grid-column: 1;
  grid-row: 1;
  place-self: center;
  font: 15px ubuntu mono, consolas, monaco, monospace;
  counter-reset: s var(--s);
  content: "0:" counter(s, decimal-leading-zero);
}

@keyframes reduce {
  to {
    --percent: 0;
  }
}
svg {
  grid-column: 1;
  grid-row: 1;
}

[r] {
  fill: none;
  stroke: silver;
}
[r] + [r] {
  --k: calc(var(--percent) / 100);
  transform: rotate(-90deg);
  stroke-linecap: round;
  stroke: color-mix(in hsl shorter hue, green calc(var(--k) * 100%), #940a3d);
  stroke-dasharray: var(--k) 1;
}
</style>
