import { group_card_type, card_detail_type } from "./groupcard";

export const MENU_GROUP_CARD = "list";
export const MENU_CARD_DETAIL = "card-detail";
export const MENU_GROUP_CARD_BROWSE_USER = "list-browse-user";
export const TABLE_HEADER_BROWSE_ADMIN = "table-header-browse-admin";
export const menu_group_card = {
  [MENU_GROUP_CARD]: {
    [group_card_type.GROUP_CARD_SAVED]: [
      {
        color: "color: #1BB763",
        icon: "bookmark",
        title: "Ôn tập trước",
        emitFunction: "study",
      },
      {
        color: "color: #FD443A",
        icon: "openbook",
        title: "Ôn tập ngẫu nhiên",
        emitFunction: "randomStudy",
      },
    ],
    [group_card_type.GROUP_CARD_CLONED_FROM_SAVED]: [
      {
        color: "color: #1BB763",
        icon: "trash",
        title: "Xóa bộ thẻ",
        emitFunction: "delete",
      },
    ],
    [group_card_type.GROUP_CARD_DEFAULT]: [
      {
        color: "color: #1BB763",
        icon: "bookmark",
        title: "Ôn tập trước",
        emitFunction: "study",
      },
      {
        color: "teal darken-2",
        useIcon: true,
        icon: "clock-edit",
        title: "Cài đặt thời gian tự động hiện đáp án",
        emitFunction: "openSetCountdownTimeModal",
        style: {
          color: "#00796b",
        },
        titleStyle: {
          whiteSpace: "unset",
        },
      },
    ],
    [group_card_type.PRO_GROUP_CARD]: [
      {
        color: "color: #1BB763",
        icon: "bookmark",
        title: "Ôn tập trước",
        emitFunction: "study",
      },
      {
        color: "color: #1BB763",
        icon: "trash",
        title: "Xóa bộ thẻ",
        emitFunction: "delete",
      },
      {
        color: "color: green",
        icon: "pencil",
        title: "Đổi tên bộ thẻ",
        emitFunction: "rename",
      },
      {
        color: "teal darken-2",
        useIcon: true,
        icon: "clock-edit",
        title: "Cài đặt thời gian tự động hiện đáp án",
        emitFunction: "openSetCountdownTimeModal",
        style: {
          color: "#00796b",
        },
        titleStyle: {
          whiteSpace: "unset",
        },
      },
    ],
    [group_card_type.PRO_GROUP_CARD_FOR_OLD_PRO_STUDENT]: [
      {
        color: "color: #1BB763",
        icon: "bookmark",
        title: "Ôn tập trước",
        emitFunction: "study",
      },
      {
        color: "teal darken-2",
        useIcon: true,
        icon: "clock-edit",
        title: "Cài đặt thời gian tự động hiện đáp án",
        emitFunction: "openSetCountdownTimeModal",
        style: {
          color: "#00796b",
        },
        titleStyle: {
          whiteSpace: "unset",
        },
      },
    ],
    [group_card_type.FOLDER]: [
      {
        color: "color: #1BB763",
        icon: "bookmark",
        title: "Ôn tập trước",
        emitFunction: "study",
      },
    ],
  },
  [MENU_CARD_DETAIL]: {
    [group_card_type.GROUP_CARD_SAVED]: [
      {
        color: "",
        icon: "save",
        title: "Bỏ lưu thẻ",
        emitFunction: "unSaveCard",
      },
      {
        color: "color: #FD443A",
        icon: "trash",
        title: "Xóa thẻ",
        emitFunction: "openDeleteDialog",
      },
      {
        color: "color: #3887FE",
        icon: "back",
        title: "Quay lại",
        emitFunction: "backCard",
      },
    ],
    [group_card_type.GROUP_CARD_CLONED_FROM_SAVED]: [
      {
        color: "color: #3887FE",
        icon: "back",
        title: "Quay lại",
        emitFunction: "backCard",
      },
    ],
    [group_card_type.GROUP_CARD_DEFAULT]: [
      {
        color: "color: #1BB763",
        icon: "save",
        title: "Lưu thẻ",
        emitFunction: "saveCard",
      },
      {
        color: "color: #FD443A",
        icon: "trash",
        title: "Xóa thẻ",
        emitFunction: "openDeleteDialog",
      },
      {
        color: "color: #3887FE",
        icon: "back",
        title: "Quay lại",
        emitFunction: "backCard",
      },
    ],
  },
  [MENU_GROUP_CARD_BROWSE_USER]: {
    [group_card_type.GROUP_CARD_SAVED]: [
      {
        color: "color: #CCCCCC",
        icon: "save",
        title: "Bỏ lưu thẻ",
        emitFunction: "unSaveCard",
      },
      {
        color: "color: #FD443A",
        icon: "trash",
        title: "Xóa thẻ",
        emitFunction: "openDeleteDialog",
      },
    ],
    [group_card_type.GROUP_CARD_DEFAULT]: [
      {
        color: "color: #1BB763",
        icon: "save",
        title: "Lưu thẻ",
        emitFunction: "saveCard",
      },
      {
        color: "color: #FD443A",
        icon: "trash",
        title: "Xóa thẻ",
        emitFunction: "openDeleteDialog",
      },
    ],
    [group_card_type.PRO_GROUP_CARD]: [
      {
        color: "color: #1BB763",
        icon: "pencil",
        title: "Đổi tên bộ thẻ",
        emitFunction: "openChangeGroupCardNameDialog",
      },
      {
        color: "color: #FD443A",
        icon: "trash",
        title: "Xóa bộ thẻ",
        emitFunction: "openDeleteGroupCardDialog",
      },
    ],
  },
  [TABLE_HEADER_BROWSE_ADMIN]: {
    [card_detail_type.ADMIN_BROWSE_TABLE]: [
      {
        color: "color: ##FD443A",
        icon: "trash",
        title: "Xóa thẻ",
        emitFunction: "unassign",
      },
      {
        color: "color: #1BB763",
        icon: "openbook",
        title: "Reschedule",
        emitFunction: "reschedule",
      },
    ],
  },
};
