<template>
  <v-dialog
    :value="showDialogValue"
    max-width="400"
    @click:outside="$emit('closeDialog')"
  >
    <v-card class="pa-8">
      <v-card-title class="pa-0 mb-7">
        <h5
          class="dialog-delete-title confirm"
          v-text="'Đặt thời gian đáp án tự hiện'"
        />
      </v-card-title>
      <v-row>
        <v-col cols="12" md="12" sm="12">
          <label>
            <h6>Nhập thời gian đáp án tự hiện (s):</h6>
          </label>
          <InputComponent
            v-model="countdownInfo.time"
            :inputProps="{ placeholder: 'Số giây (s)', typeInput: 'number' }"
            class="mt-3"
          />
        </v-col>
        <v-col cols="12" md="12" sm="12">
          <label>
            <h6>Hiện đồng đồ đếm ngược</h6>
          </label>
          <v-switch
            v-model="countdownInfo.show"
            :label="countdownInfo.show ? 'Hiện' : 'Ẩn'"
            :false-value="false"
            :true-value="true"
            hide-details
          ></v-switch>
        </v-col>
        <v-col cols="12" md="12" sm="12">
          <v-checkbox v-model="isAppliedForMultipleGroups">
            <template v-slot:label>
              <h6>Áp dụng cho các bộ thẻ khác</h6>
            </template>
          </v-checkbox>
          <treeselect
            v-model="selectedCardGroupList"
            :disabled="!isAppliedForMultipleGroups"
            :options="otherGroupCards"
            multiple
            clearable
            searchable
            showCountx
            searchNested
            openDirection="below"
            placeholder="Chọn các bộ thẻ khác muốn áp dụng."
          />
        </v-col>
      </v-row>
      <v-spacer></v-spacer>
      <v-card-actions class="d-flex align-center justify-end pa-0 mt-6">
        <v-btn class="cancel-btn" elevation="0" @click="$emit('closeDialog')">
          <h6>Huỷ</h6>
        </v-btn>
        <ButtonComponent
          title="Tắt"
          class="ml-3"
          color="error"
          :disable="!countdownDataProp.time"
          @click="turnOffCountdown"
        />
        <ButtonComponent
          title="Lưu"
          class="ml-3"
          :disable="false"
          @click="handleConfirmRequest(countdownInfo)"
        />
      </v-card-actions>
      <v-snackbar
        v-model="isTimeZero"
        absolute
        location="bottom"
        color="red accent-2"
        :timeout="2000"
      >
        Không thể chọn thời gian là 0.
      </v-snackbar>
    </v-card>
  </v-dialog>
</template>

<script>
import ButtonComponent from "@/components/ui/ButtonComponent.vue";
import InputComponent from "@/components/ui/InputComponent.vue";
import { mapGetters } from "vuex";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  components: { InputComponent, ButtonComponent, Treeselect },
  props: {
    countdownDataProp: {
      type: Object,
      default: () => {
        return {
          time: 0,
          show: false,
        };
      },
    },
    showDialogValue: {
      type: Boolean,
      default: false,
    },
    currentUserGroupCardId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      countdownInfo: {
        time: 0,
        show: false,
      },
      isTimeZero: false,
      isAppliedForMultipleGroups: false,
      selectedCardGroupList: [],
    };
  },
  watch: {
    showDialogValue: {
      handler() {
        if (this.countdownDataProp?.time) {
          this.countdownInfo = { ...this.countdownDataProp };
        } else {
          this.countdownInfo = {
            time: 0,
            show: false,
          };
        }
      },
    },
  },
  computed: {
    ...mapGetters("list", ["getAllGroupCardToSetCountdownTime"]),
    otherGroupCards() {
      return this.getAllGroupCardToSetCountdownTime.filter(
        (groupCard) => groupCard.id != this.currentUserGroupCardId
      );
    },
    isInvalid() {
      return !this.countdownInfo.time;
    },
    studentId() {
      return this.$route.params.studentId;
    },
  },
  created() {
    this.countdownInfo = { ...this.countdownDataProp };
  },
  methods: {
    turnOffCountdown() {
      const payload = {
        time: 0,
        show: this.countdownInfo.show,
      };
      this.$emit("confirmRequest", payload);
    },
    async handleConfirmRequest(payload) {
      if (payload.time == 0) {
        this.isTimeZero = true;
        return;
      }
      await this.$emit("confirmRequest", {
        ...payload,
        appliedGroupCardIds: this.isAppliedForMultipleGroups
          ? [this.currentUserGroupCardId, ...this.selectedCardGroupList]
          : [this.currentUserGroupCardId],
      });
    },
    getImgUrl(url) {
      return require("@/assets/" + url);
    },
  },
  // mounted() {
  //   const handleKeyPressed = (e) => {
  //     if (e.key === "Enter" && e.ctrlKey) {
  //       if (this.showDialogValue) {
  //         this.handleSaveAccount();
  //       }
  //     }
  //   };
  //   window.addEventListener("keydown", handleKeyPressed);
  //   this.$once("hook:beforeDestroy", () => {
  //     window.removeEventListener("keydown", handleKeyPressed);
  //   });
  // },
};
</script>

<style lang="sass" scoped>
// ----- dialog delete --------- //
.dialog-delete-title
  font-size: 22px
  color: #1C283D

.dialog-delete-subtitle
  color: #384961

.cancel-btn
  background-color: transparent !important
  text-transform: initial
  color: #453FE3

// ------- dialog success ------- //
.v-card
  border-radius: 12px !important

.dialog-success-title
  font-size: 22px
  color: #384961
  word-break: normal
  line-height: 33px
  width: 100%

.v-responsive__content
  justify-content: center
  display: flex
.bgsize
  background-image: url(@/assets/images/firework-bg.svg)
  background-size: cover
</style>

<style lang="scss" scoped>
::v-deep .vue-treeselect {
  .vue-treeselect__menu {
    max-height: 250px;
  }
}

::v-deep .v-dialog,
::v-deep .v-window {
  overflow: visible !important;
}

.v-snack--absolute {
  z-index: 2;
}
.dialog-delete-title.confirm {
  word-break: break-word;
}
.add-new-folder {
  height: 36px;
  &:focus {
    outline: none;
  }
}
input {
  width: 100%;
  padding: 16px 0 16px 16px;
  border: 1px solid #d9dfea;
  border-radius: 12px;
  background-color: #f9fbfc;
  min-width: 305px;
  &.smd {
    min-width: unset;
  }
}
.allocate-input {
  margin: 0 10px;
  width: 50px;
  border: 1px solid black;
  padding: 1px;
}
.reviewAheadCheck {
  margin-top: 8px;
  margin-left: -2px;
}
.input-study {
  min-width: unset;
}
</style>
