import { group_card_type } from "@/constants/groupcard";

export default {
  getListCardGroup(state) {
    return state.cardGroupList;
  },
  getListCardGroupInFolder: (state) => (folderId) => {
    return state.cardGroupList.proFolders.find(
      (folder) => folder.folderId === folderId
    ).cardGroups;
  },
  studentGetAllProGroupCard(state) {
    let arr = [];
    state.cardGroupList.proFolders.forEach((folder) => {
      arr = [...arr, ...folder.cardGroups];
    });
    return arr;
  },
  getAllGroupCardToSetCountdownTime(state) {
    let arr = state.cardGroupList.cardGroups.filter(
      (groupCard) => groupCard.type != group_card_type.GROUP_CARD_SAVED
    );
    state.cardGroupList.proFolders.forEach((folder) => {
      arr = [...arr, ...folder.cardGroups];
    });
    return arr.map((groupCard) => ({
      id: groupCard.id,
      label: groupCard.groupName,
    }));
  },
  getLearningResult(state) {
    return state.learningResult;
  },
  getListCard(state) {
    return state.cardList;
  },
};
