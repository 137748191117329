import axios from "@/services/axios";

const BASE_URL = "student/";

export default {
  async updateProCardSet(context, { cardSetId, cardSetName }) {
    await axios
      .put(`${BASE_URL}groupCard/${cardSetId}`, {
        groupName: cardSetName,
      })
      .then((res) => {
        if (res.data.status_code !== "200") {
          const error = res.data.message || "Something is wrong!";
          throw error;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async updateCountdownTime(
    context,
    { countDownTime, showCountDown, userGroupCardIds }
  ) {
    await axios
      .put(`${BASE_URL}userGroupCard/count-down-time`, {
        countDownTime,
        showCountDown,
        userGroupCardIds,
      })
      .then((res) => {
        if (res.data.status_code !== "200") {
          const error = res.data.message || "Something is wrong!";
          throw error;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async updateProCardDetail(context, { id, form }) {
    await axios
      .put(`${BASE_URL}card/${id}`, form, {
        headers: {
          "Content-Type": "multipart/encrypted",
        },
      })
      .then((res) => {
        if (res.data.status_code !== "200") {
          const error = res.data.message || "Something is wrong!";
          throw error;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async studentCreateProCard(context, form) {
    await axios
      .post(`${BASE_URL}card`, form, {
        headers: {
          "Content-Type": "multipart/encrypted",
        },
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  async deleteProCard(context, ids) {
    await axios
      .delete(`${BASE_URL}cards`, {
        data: { ids },
      })
      .then((res) => {
        if (res.data.status_code !== "200") {
          const error = res.data.message || "Something is wrong!";
          throw error;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async assignProCardGroup(context, payload) {
    await axios
      .post(`${BASE_URL}groupCard/${payload.groupCardId}`, payload.body)
      .then((res) => {
        if (res.data.status_code !== "200") {
          const error = res.data.message || "Something is wrong!";
          throw error;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async studentCreateProCardGroup(context, payload) {
    await axios
      .post(`${BASE_URL}groupCard`, payload)
      .then((res) => {
        if (res.data.status_code !== "200") {
          const error = res.data.message || "Something is wrong!";
          throw error;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async studentDeleteProGroupCard(context, id) {
    await axios
      .delete(`${BASE_URL}groupCard/${id}`)
      .then((res) => {
        if (res.data.status_code !== "200") {
          const error = res.data.message || "Something is wrong!";
          throw error;
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 401) {
          context.commit(
            "common/SET_DIALOG",
            {
              description:
                "Đây là bộ thẻ do Admin tạo. Bạn không thẻ xoá bộ thẻ này!",
            },
            { root: true }
          );
        }
      });
  },
};
